import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
    render () {
        return (
            <Layout>
                <section className="section" style={{ paddingTop: 0 }}>
                    <div className="container">
                        <div className="content">
                            <div
                                className="full-width-image-container margin-top-0"
                                style={{
                                    backgroundImage: `url('/img/blog-index.jpg')`,
                                    backgroundAttachment: `fixed`,
                                }}
                            >
                                <h2
                                    className="has-text-weight-bold is-size-1"
                                    style={{
                                        boxShadow: '0.5rem 0 0 #00640a, -0.5rem 0 0 #00640a',
                                        backgroundColor: '#00640a',
                                        color: 'white',
                                        padding: '0.25rem',
                                    }}
                                >
                                    Latest Stories
                                </h2>
                            </div>
                        </div>
                        <BlogRoll />
                    </div>
                </section>
            </Layout>
        )
    }
}
